import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."
import { getSuggestedPrice } from "../../api"
import { parseNumber } from "../../utils"

export const AppraiseStep2: React.FC<StepProps> = ({
  form,
  updateForm,
  toggleLoading,
}) => {
  const finishStep = async () => {
    toggleLoading()
    // Cars older than 100.000 kms are not appraised automatically
    if (
      (form.kms || 0) > form.maxKmsAppraised ||
      (form.info.carInfo.year || 0) <= 2012
    ) {
      toggleLoading()
      return form.actions.thanksPage(
        "Kilometraje del vehículo mayor o igual a 100.000 kms."
      )
    }

    try {
      const price = await getSuggestedPrice(
        form.info.carInfo?.model_id,
        form.info.carInfo?.year,
        form.info.carInfo?.brand_id,
        form.version,
        form.kms
      )
      if (price?.warnings && price?.warnings.currency === "USD") {
        form.actions.thanksPage("Tasación en USD")
      } else {
        updateForm(prev => ({
          ...prev,
          info: {
            ...prev.info,
            carInfo: {
              ...prev.info.carInfo,
              suggestedPrice: price?.sugested_price,
              publicationPrice: price?.publication_price,
            },
          },
        }))
        form.actions.nextStep()
      }
    } catch (e) {
      form.actions.thanksPage(
        "Falló la solicitud para obtener un Precio Sugerido"
      )
    }
    toggleLoading()
  }

  return (
    <>
      <CarInfo data={form} />
      <div className="title">¿Quieres recibir una oferta?</div>
      <div className="subtitle">Necesitamos el detalle de tu auto</div>
      <div className="component">
        <FormControl
          className="select-dense"
          id="select-version"
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-native-simple-1">Versión</InputLabel>
          <Select
            native
            value={form.version || ""}
            onChange={({ target: { value } }) => {
              updateForm(prev => ({
                ...prev,
                version: value ? Number(value) : undefined,
              }))
            }}
            label="Versión"
            inputProps={{
              name: "version",
              id: "outlined-native-simple-1",
            }}
          >
            <option aria-label="None" value="" />
            {form.info.versions?.map((e, idx) => (
              <option key={idx} value={e.value}>
                {e.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl className="select-dense" id="select-km" variant="outlined">
          <InputLabel htmlFor="outlined-native-simple-2">
            Kilometraje
          </InputLabel>
          <Select
            native
            value={form.kms || ""}
            onChange={({ target: { value } }) => {
              updateForm(prev => ({
                ...prev,
                kms: value ? Number(value) : undefined,
              }))
            }}
            label="Kilometraje"
            inputProps={{
              name: "km",
              id: "outlined-native-simple-2",
            }}
          >
            <option aria-label="None" value="" />
            {generateKMSoptions(form.maxKmsAppraised).map((e, idx) => (
              <option key={idx} value={e.value}>
                {e.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-2"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next select-km-version"
          id="finish-step-2"
          type="primary"
          text="SIGUIENTE"
          disabled={!form.version || !form.kms}
          onClick={finishStep}
        />
      </div>
    </>
  )
}

const generateKMSoptions = (maxKms: number) =>
  [5000]
    .concat(Array.from(Array(maxKms / 10000).keys()).map(e => (e + 1) * 10000))
    .map(value => ({
      value,
      label: parseNumber(value) + " kms",
    }))
