import React from "react"
import { CarInfo, StepProps } from "."
import { Divider } from "@material-ui/core"
import { DCXButton } from ".."
import { parseNumber } from "../../utils"

export const AppraiseStep7: React.FC<StepProps> = ({ form }) => {
  const finishStep = () => {
    form.actions.SetCarPaymentInfo({
      finalPrice: form.info.carInfo.finalPrice,
      plate: form.info.carInfo.plate as string,
      date: new Date().getTime(),
    })
    form.actions.closeAppraiseDrawer(true)
  }
  const discount = form.publicationDiscountPercentage * 100
  const publicationPrice = form.info.carInfo?.publicationPrice ?? 0
  const publicationPriceAdjusted =
    publicationPrice * (1 - form.publicationDiscountPercentage)

  return (
    <>
      <CarInfo data={form} />
      <div className="title">Recibimos tu auto en:</div>
      <div className="subtitle">Precio Retoma:</div>
      <div className="component">
        <div className="amount">
          ${parseNumber(form.info.carInfo?.suggestedPrice)}
        </div>
      </div>
      <div className="subtitle">Precio BottomUp:</div>
      <div className="component">
        <div className="amount">
          ${parseNumber(form.info.carInfo.finalPrice)}
        </div>
        {/* <div className="disclaimer">
          Precio referencial sujeto a la inspección de tu auto. Simula para
          descontar este valor en el precio total de tu nuevo auto.
        </div> */}
      </div>
      <Divider />
      <div className="subtitle">Precio TopDown (-{discount}%):</div>
      <div className="component">
        <div className="amount">${parseNumber(publicationPriceAdjusted)}</div>
      </div>
      <div className="subtitle">Precio Publicación:</div>
      <div className="component">
        <div className="amount">${parseNumber(publicationPrice)}</div>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-7"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next save-appraise"
          id="accept-final-value"
          type="primary"
          text="SIMULAR"
          onClick={finishStep}
        />
      </div>
    </>
  )
}
