import api from "./api"
import { parseNumber } from "../utils"
import { DataPlate } from "../types"
import { FormData } from "../components/appraise-steps"

type AppraiseEntity = {
  id: number
  name: string
}

type SuggestedPriceResponse = {
  sugested_price: number
  publication_price: number
  warnings: {
    currency: string
  }
}

export async function getBrands(): Promise<AppraiseEntity[]> {
  try {
    const response = await api.get<AppraiseEntity[]>("/getBrands")
    return response.data || []
  } catch (e) {
    return []
  }
}

export async function getModels(brandId: number): Promise<AppraiseEntity[]> {
  try {
    const response = await api.post<AppraiseEntity[]>(`/getModels`, { brandId })
    return response.data || []
  } catch (e) {
    return []
  }
}

export async function getYears(modelId: number): Promise<number[]> {
  try {
    const response = await api.post<number[]>(`/getYears`, { modelId })
    return response.data || []
  } catch (e) {
    return []
  }
}

export async function getDataPlate(
  licensePlate: string
): Promise<DataPlate | null> {
  try {
    const response = await api.post<DataPlate>(`/getDataPlate`, {
      licensePlate,
    })
    return response.data || null
  } catch (e) {
    return null
  }
}

export async function getVersions(
  modelId?: number,
  year?: number
): Promise<AppraiseEntity[]> {
  try {
    if (!modelId || !year) return []
    const response = await api.post<AppraiseEntity[]>(`/getVersions`, {
      modelId,
      year,
    })
    return response.data || []
  } catch (e) {
    return []
  }
}

export async function getSuggestedPrice(
  modelId?: number,
  year?: number,
  brandId?: number,
  versionId?: number,
  km?: number
): Promise<SuggestedPriceResponse | null> {
  try {
    if (!modelId || !year || !brandId || !versionId || !km) return null
    const response = await api.post<SuggestedPriceResponse>(
      `/getSuggestedPrice`,
      {
        modelId,
        year,
        brandId,
        versionId,
        km,
      }
    )
    return response.data || null
  } catch (e) {
    return null
  }
}

export async function getFinalPrice(
  brand?: string,
  model?: string,
  price?: number,
  star?: number,
  carKeys?: boolean,
  changeTires?: boolean,
  numberTires?: number | null,
  maintenanceStatus?: number | null
): Promise<{ finalPrice: number } | null> {
  try {
    if (
      !brand ||
      !model ||
      !price ||
      !star ||
      typeof carKeys === "undefined" ||
      typeof changeTires === "undefined" ||
      typeof numberTires === "undefined" ||
      typeof maintenanceStatus === "undefined"
    )
      return null
    const response = await api.post<{ finalPrice: number }>(`/getFinalPrice`, {
      brand,
      model,
      price,
      star,
      carKeys: Boolean(carKeys),
      changeTires: Boolean(changeTires),
      numberTires,
      maintenanceStatus,
    })
    return response.data || null
  } catch (e) {
    return null
  }
}

export async function saveAppraise(
  form: FormData,
  success: boolean
): Promise<number> {
  try {
    const body = {
      plate: form.plate.replace(/·/g, ""),
      finalPrice: form.info.carInfo.finalPrice
        ? "$" + parseNumber(form.info.carInfo.finalPrice)
        : null,
      success,
      data: {
        carInfo: {
          vehicle_type: form.info.carInfo.vehicle_type,
          brand_name: form.info.carInfo.brand_name,
          model_name: form.info.carInfo.model_name,
          version_name: form.info.versions?.find(v => v.value === form.version)
            ?.label,
          color: form.info.carInfo.color,
          plate: form.info.carInfo.plate,
          year: form.info.carInfo.year,
          suggestedPrice: form.info.carInfo.suggestedPrice
            ? "$" + parseNumber(form.info.carInfo.suggestedPrice)
            : null,
          publicationPrice: form.info.carInfo?.publicationPrice
            ? "$" + parseNumber(form.info.carInfo.publicationPrice)
            : null,
          finalPrice: form.info.carInfo.finalPrice
            ? "$" + parseNumber(form.info.carInfo.finalPrice)
            : null,
        },
        userSelections: {
          version:
            form.info.versions?.find(v => v.value === form.version)?.label ||
            null,
          kms: form.kms ? parseNumber(form.kms) : null,
          stars: form.stars ?? null,
          maintenanceStatus: form.maintenanceStatus ?? null,
          carKeys: form.carKeys ?? null,
          oneOwner: form.oneOwner ?? null,
          changeTires: form.changeTires ?? null,
          changeTiresQuantity: form.changeTiresQuantity ?? null,
        },
        personalData: form.name
          ? {
              name: form.name,
              email: form.email,
              phone: form.phone,
            }
          : null,
        plateFallback: form.info.plateFallback
          ? {
              brand: form.info.brands?.find(b => b.value === form.brand)?.label,
              model: form.info.models?.find(m => m.value === form.model)?.label,
              year: form.year,
            }
          : null,

        formInfo: {
          reasonForThanksPage: form.reasonForThanksPage,
          step: form.info.step,
          stepHistory: form.info.stepHistory.join(", "),
          plateFallback: form.info.plateFallback,
        },
      },
    }
    await api.post(`/appraises`, body)
    return 0
  } catch (e) {
    return 1
  }
}
